<template>
  <div class="product-container">
    <div class="product-list">
      <van-swipe-cell :disabled="disabled" right-width="64">
        <div class="product-item">
          <div class="item-img" @click="goProDetail">
            <img
              :src="prtPictureList[0] && prtPictureList[0].filePath"
              alt=""
            />
          </div>
          <div class="item-content">
            <div v-if="info.isColl == 1" class="shou-cang">
              <img src="../../assets/img/proDetails/shoucang.png" alt="" />
            </div>
            <span class="prt-title">{{ info.prtName }}</span>
            <span class="prt-inch"
              >尺寸：<span v-if="info.prtLong && info.prtWide && info.prtHigh"
                >{{ info.prtLong }}cm * {{ info.prtWide }}cm *
                {{ info.prtHigh }}cm</span
              >
              <span v-else>暂无</span>
            </span>

            <span class="prt-content"
              >含量：{{ info.prtContent || "暂无" }}</span
            >
            <span class="prt-price"
              >价格：
              <span>{{ info.prtPrice || "面议" }}</span>
            </span>
            <div class="item-btn">
              <div class="btn-left" @click="goProDetail">
                <img src="../../assets/img/listCard/btn-left.png" alt="" />
                <span>详情</span>
              </div>
              <div
                class="btn-right"
                :class="hasVideo ? '' : 'no-video'"
                @click="handleVideoPlay"
              >
                <img src="../../assets/img/listCard/btn-right.png"/>
                <span>视频</span>
              </div>
            </div>
          </div>
        </div>
        <template #right>
          <van-button
            square
            text="删除"
            type="danger"
            class="delete-button"
            @click="handleDelete"
          />
        </template>
      </van-swipe-cell>
    </div>
  </div>
</template>

<script>
import { setPageTitle } from "@/utils";
import { getFileList, clickPrtAddOne } from "@/api";
import _ from "lodash";
export default {
  name: "ProductList",
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    info: {
      type: Object,
    },
  },
  data() {
    return {
      // 产品视频列表--------------------------------------------
      prtVideoList: [],
    };
  },

  computed: {
    hasVideo() {
      const picList = this.info?.picList || [];
      const video = picList.filter((item) => item.typeName == "prtVideo");
      return video.length > 0;
    },
    // 产品图片列表--------------------------------------------
    prtPictureList() {
      const picList = this.info?.picList || [];
      return picList.filter((o) => o.typeName == "prtPicture");
    },
    // 视频图片
    videoPictureList() {
      const picList = this.info?.picList || [];
      return picList.filter((o) => o.typeName == "videoPicture");
    },
    // 产品详情列表--------------------------------------------
    prtDetailList() {
      const picList = this.info?.picList || [];
      return picList.filter((o) => o.typeName == "prtInfo");
    },
    // 生成视频左下角信息
    getVideoInfo() {
      return _.reduce(
        this.info,
        (result, value, key) => {
          if (value) {
            switch (key) {
              case "prtName":
                if (this.info.isName == 0) {
                  result.push({ label: "名称", value });
                }
                break;
              case "prtCode":
                if (this.info.isCode == 0) {
                  result.push({ label: "货号", value });
                }
                break;
              case "prtType":
                if (this.info.isType == 0) {
                  result.push({ label: "分类", value });
                }
                break;
              case "prtLong":
                if (this.info.isChiCun == 0) {
                  result.push({
                    label: "尺寸",
                    value: `${value}cm*${this.info.prtWide}cm*${this.info.prtHigh}cm`,
                  });
                }
                break;
              case "prtSpecs":
                if (this.info.isSpecs == 0) {
                  result.push({ label: "规格", value });
                }
                break;
              case "prtContent":
                if (this.info.isContent == 0) {
                  result.push({ label: "含量", value });
                }
                break;
              case "prtHairNum":
                if (this.info.isHairNum == 0) {
                  result.push({ label: "发数", value: value + "发" });
                }
                break;
              case "prtInch":
                if (this.info.isInch == 0) {
                  result.push({ label: "寸数", value: value + "寸" });
                }
                break;
              case "prtWeight":
                if (this.info.isWeight == 0) {
                  result.push({ label: "重量", value: value + "kg" });
                }
                break;
              case "prtEffect":
                if (this.info.isEffect == 0) {
                  result.push({ label: "效果", value });
                }
                break;
            }
          }
          return result;
        },
        []
      );
    },
  },
  created() {
    // this.getFileList()
  },
  methods: {
    async handleVideoPlay() {
      if (!this.hasVideo) return;
      this.prtVideoList = await this.getFileList();
      // if (!this.prtVideoList.length) return
      // 增加视频点击量
      clickPrtAddOne({ uuid: this.info.uuid });

      const videoArr = this.prtVideoList.map((item, index) => {
        const oneVideo = {
          id: index,
          bgImg:
            this.info.isPic == 0
              ? this.videoPictureList[0]?.filePath ?? ""
              : "",
          isLoad: index == 0,
          sources: item.filePath || "",
          infoArr: this.getVideoInfo,
          uuid: this.info.uuid,
          isLike: this.info.isLike,
        };
        return oneVideo;
      });
      localStorage.setItem("videoArr", JSON.stringify(videoArr));
      this.$router.push({
        name: "VideoPlay",
        query: {
          uuid: this.info.uuid,
          type: "noClose",
          title: this.info.prtName,
        },
      });
    },
    goProDetail() {
      this.$router.push({
        name: "ProductDetails",
        query: { uuid: this.info.uuid,title:this.info.prtName },
      });
    },

    // 获取视频
    getFileList() {
      const params = {
        page: 0,
        pageSize: 0,
        typeName: "prtVideo",
        parentUuid: this.info.uuid,
      };
      return getFileList(params).then((res) => {
        if (res.state == 100) {
          return res.items;
          // this.prtVideoList = res.items
        }
      });
    },
    handleDelete() {
      this.$emit("handleDelete", this.info);
    },
  },
};
</script>

<style lang="less" scoped>
.product-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .van-swipe-cell {
    width: 100%;
    margin-bottom: 7px;

    /deep/ .van-swipe-cell__wrapper {
      display: flex;
      justify-content: center;
      .product-item {
        width: 361px;
        height: 150px;
        background: #ffffff;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.03);
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .item-img {
          width: 130px;
          height: 130px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
        .item-content {
          width: 191px;
          height: 116.5px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          position: relative;

          .shou-cang {
            position: absolute;
            top: 0;
            right: 0;
            & > img {
              width: 14px;
              height: 15px;
            }
          }

          & > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .prt-title {
            width: 170px;
            font-size: 15px;
            color: #000000;
            margin: 0 0 8px;
            font-weight: 400;
          }

          .prt-inch,
          .prt-content {
            font-size: 11px;
            color: #989898;
            font-family: PingFang SC;
            font-weight: 400;
          }
          .prt-content {
            margin: 4px 0 5px;
          }

          .prt-price {
            font-size: 12px;
            color: #ff1448;
            margin-bottom: 8px;
          }

          .item-btn {
            width: 176px;
            height: 33px;
            /*box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);*/
            border-radius: 16px;
            overflow: hidden;
            display: flex;
            position: relative;

            .btn-left,
            .btn-right {
              /*flex: 1;*/
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 13px;
              img {
                width: 17px;
                height: 17px;
                margin-right: 5px;
              }
              span {
                height: 17px;
                line-height: 18px;
              }
            }

            .btn-left {
              width: 90.5px;
              background: url("../../assets/img/listCard/btn-left.png") no-repeat;
              background-size: 100% 100%;
              margin-right: -8px;
              //  background: linear-gradient(90deg, #8be73f 0%, #4d5af1 0%, #636fff 100%);
            }

            .btn-right {
              width: 102.5px;
              background: url("../../assets/img/listCard/btn-right.png") no-repeat;
              background-size: 100% 100%;
              margin-left: -7px;
              // background: linear-gradient(90deg, #b63cfb 0%, #ff3cf8 100%);
            }

            .no-video {
              background: url("../../assets/img/listCard/no-video.png") no-repeat;
              background-size: 100% 100%;
              // background: linear-gradient(90deg, #838383 0%, #c3c3c3 100%);
            }
          }
        }
      }

      .delete-button {
        width: 65px;
        height: 100%;
        background: #ff1448;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.03);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    }
  }
}
</style>
